/**
 * Created by dmitry on 07.03.2025
 */

var LastPurchaseWindow = CleverappsWindow.extend({
    onWindowLoaded: function (offer) {
        this.logic = offer.logic;
        this.product = offer.logic.getProduct();

        this._super({
            name: "LastPurchaseWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            content: this.createContent()
        });
    },

    createContent: function () {
        this.bg = new cleverapps.Spine(bundles.last_purchase.jsons.last_purchase_bg_json);

        this.createTitle();
        this.createDescription();
        this.createMainLabel();
        this.createBonusTitle();
        this.createBonusLabel();
        this.createBonusText();
        this.createFirstFooter();
        this.createSecondFooter();
        this.createBuyButton();

        this.bg.setAnimation(0, "animation", true);
        return this.bg;
    },

    createTitle: function () {
        var slot = this.bg.getSlot("slot_title");
        var text = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.title",
            cleverapps.styles.FONTS.LAST_PURCHASE_LAGE_BRIGHT
        );
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createDescription: function () {
        var slot = this.bg.getSlot("slot_description");

        var firstPart = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.descriptionFirstPart",
            cleverapps.styles.FONTS.LAST_PURCHASE_SMALL,
            { amount: this.product.reward.hard || this.product.reward.rubies || this.product.reward.gold }
        );

        firstPart.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        firstPart.setDimensions(slot.width, 0);

        var secondPart = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.descriptionSecondPart",
            cleverapps.styles.FONTS.LAST_PURCHASE_SMALL_BRIGHT
        );
        secondPart.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        secondPart.setDimensions(slot, 0);

        var description = new cleverapps.Layout([firstPart, secondPart], {
            direction: cleverapps.UI.VERTICAL
        });
        cleverapps.UI.fitToBox(description, {
            width: slot.width,
            height: slot.height
        });
        description.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });

        slot.addChild(description);
    },

    createMainLabel: function () {
        var slot = this.bg.getSlot("slot_main_label");
        var rewardList = new RewardsListComponent(this.product.reward, {
            font: cleverapps.styles.FONTS.LAST_PURCHASE_LAGE,
            textDirection: cleverapps.UI.HORIZONTAL,
            small: true,
            noPrefix: true
        });
        rewardList.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(rewardList);
    },

    createBonusTitle: function () {
        var slot = this.bg.getSlot("slot_bonus_title");
        var text = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.bonusTitle",
            cleverapps.styles.FONTS.LAST_PURCHASE_LAGE_BRIGHT
        );
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createBonusLabel: function () {
        var slot = this.bg.getSlot("slot_bonus_label");
        var rewardList = new RewardsListComponent(LastPurchase.BONUS_REWARD, {
            font: cleverapps.styles.FONTS.LAST_PURCHASE_LAGE,
            textDirection: cleverapps.UI.HORIZONTAL,
            small: true,
            noPrefix: true
        });
        rewardList.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(rewardList);
    },

    createBonusText: function () {
        var slot = this.bg.getSlot("slot_bonus_text");
        var text = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.bonusText",
            cleverapps.styles.FONTS.LAST_PURCHASE_SMALL_DARK
        );
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createFirstFooter: function () {
        var slot = this.bg.getSlot("slot_first_footer");
        var text = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.firstFooter",
            cleverapps.styles.FONTS.LAST_PURCHASE_LAGE_BRIGHT
        );
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createSecondFooter: function () {
        var slot = this.bg.getSlot("slot_second_footer");
        var text = cleverapps.UI.generateOnlyText(
            "LastPurchaseWindow.secondFooter",
            cleverapps.styles.FONTS.LAST_PURCHASE_SMALL
        );
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createBuyButton: function () {
        var styles = cleverapps.styles.LastPurchaseWindow.buyButton;
        var slot = this.bg.getSlot("slot_buy_button");

        var button = new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            onClicked: function () {
                Object.assign(this.product.reward, LastPurchase.BONUS_REWARD);

                this.product.buy(
                    function (success) {
                        this.logic.processBought(success, this.product);
                        this.close();
                    }.bind(this)
                );
            }.bind(this),
            width: styles.width,
            height: styles.height
        });

        button.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(button);
    },

    getPerson: function () {
        return "seller";
    },

    listBundles: function () {
        return ["last_purchase"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LAST_PURCHASE_LAGE: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    LAST_PURCHASE_LAGE_BRIGHT: {
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_LIGHT_YELLOW,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE
    },

    LAST_PURCHASE_SMALL: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    LAST_PURCHASE_SMALL_BRIGHT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_LIGHT_YELLOW,
        stroke: cleverapps.styles.DECORATORS.BROWN_STROKE
    },

    LAST_PURCHASE_SMALL_DARK: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_BROWN
    }

});

cleverapps.styles.LastPurchaseWindow = {
    buyButton: {
        width: 270,
        height: 115
    }
};