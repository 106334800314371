/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSaleWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "FallSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            fireworks: true,
            content: this.createContent(),
            openSound: bundles.fall_sale_window.urls.sale_showup_effect
        });
    },

    createContent: function () {
        this.bg = new cleverapps.Spine(bundles.fall_sale_window.jsons.sale_bg);

        this.createTitle();
        this.createDescription();
        this.createButton();

        this.bg.setAnimationAndIdleAfter("open", "idle");
        return this.bg;
    },

    createTitle: function () {
        var slot = this.bg.getSlot("slot_title");
        var text = cleverapps.UI.generateOnlyText("FallSaleWindow.title", cleverapps.styles.FONTS.FALL_SALE_TITLE);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createDescription: function () {
        var slot = this.bg.getSlot("slot_description");
        var text = cleverapps.UI.generateOnlyText("FallSaleWindow.description", cleverapps.styles.FONTS.FALL_SALE_DESCRIPTION);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(slot.width, 0);
        text.fitTo(undefined, slot.height);
        text.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        slot.addChild(text);
    },

    createButton: function () {
        var styles = cleverapps.styles.FallSaleWindow.button;

        var slot = this.bg.getSlot("slot_button");

        var button = new cleverapps.UI.Button({
            text: "FallSaleWindow.Button.text",
            onClicked: function () {
                new HardCurrencyShopWindow({ attention: FallSale.CONFIG.products });
                this.close();
            }.bind(this),
            width: styles.width,
            height: styles.height
        });
        button.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });

        slot.addChild(button);
    },

    listBundles: function () {
        return ["fall_sale_window"];
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "hero");
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FALL_SALE_TITLE_SHADOW: {
        color: new cc.Color(187, 60, 0, 255),
        direction: cc.size(4, -6)
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FALL_SALE_TITLE: {
        size: 100,
        color: new cc.Color(255, 253, 81, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_TITLE_SHADOW
    },

    FALL_SALE_DESCRIPTION: {
        size: 24,
        color: new cc.Color(178, 237, 246, 255)
    }
});

cleverapps.styles.FallSaleWindow = {
    button: {
        width: 460,
        height: 100
    }
};