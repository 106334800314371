/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSale = function () {
    
};

FallSale.prototype.isAvailable = function () {
    return cleverapps.user.checkAvailable(FallSale.CONFIG.available)
        && cleverapps.eventManager.isActive("fallsale")
        && cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR
        && !cleverapps.config.wysiwygMode;
};

FallSale.prototype.needToShowWindow = function () {
    return this.isAvailable() && (!Map2d.currentMap || Map2d.currentMap.fogs.isOpened("fog3")) && !cleverapps.config.adminMode;
};

FallSale.prototype.isProductForSale = function (itemId) {
    if (this.isAvailable()) {
        return FallSale.PRODUCTS[itemId];
    }
};

FallSale.prototype.showWindow = function () {
    cleverapps.focusManager.display({
        focus: "FallSaleWindow",
        action: function (f) {
            new FallSaleWindow();

            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

FallSale.PRODUCTS = {
    gold3800: {
        icon: "shop_gold_medium_winter_sale_png",
        badge: "Badge.sale"
    },
    gold6000: {
        icon: "shop_gold_large_winter_sale_png",
        badge: "Badge.sale"
    },
    gold25000: {
        icon: "shop_gold_huge_winter_sale_png",
        badge: "Badge.sale"
    }
};

FallSale.CONFIG = {
    products: ["gold3800", "gold6000", "gold25000"],
    available: {
        source: ["test", "ok", "vk"]
    }
};

cleverapps.InitByFeature["fallsale"] = function () {
    cleverapps.fallSale = new FallSale();
};