/**
 * Created by iamso on 21.06.19.
 */

var MineSweeperNode = cc.Node.extend({
    ctor: function (game) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.MineSweeperNode);

        this.game = game;

        this.createField();
        this.createScore();
        this.createProgress();
        this.createBucket();

        this.game.on("open", this.open.bind(this), this);
        this.game.on("bomb", this.bomb.bind(this), this);
        this.game.on("prize", this.prize.bind(this), this);
        this.game.on("explodePrize", this.explodePrize.bind(this), this);
        this.game.on("openField", this.openField.bind(this), this);
        this.game.on("showBomb", this.showBomb.bind(this), this);
        this.game.on("removeFlag", this.removeFlag.bind(this), this);
        this.game.on("addFlag", this.addFlag.bind(this), this);
        this.game.on("showPrize", this.showPrize.bind(this), this);
        this.game.on("victory", this.onVictory.bind(this), this);
        this.game.on("cellsToOpen", this.updateProgress.bind(this), this);

        this.createBg();
        this.controls();
    },

    onVictory: function () {
        this.hideTopBlock();
        this.hideFieldNode();
        this.hideProgress();
    },

    createBg: function () {
        var bg = new cleverapps.Spine(bundles.minesweeper.jsons.field_bg);
        bg.setAnimation(0, "animation", true);
        bg.setPositionRound(cleverapps.styles.MineSweeperNode.bg);
        this.addChild(bg, -1);
    },

    createBucket: function () {
        var styles = cleverapps.styles.MineSweeperNode.bucket;
        this.bucket = new cc.Node();
        this.bucket.setLocalZOrder(10);
        this.bucket.setAnchorPoint(0.5, 0.5);
        this.bucket.setContentSize2(styles);

        var image = new cc.Sprite(bundles.minesweeper.frames.bucket_png);
        image.setScale(1.5);
        image.setPositionRound(styles.image);
        this.bucket.addChild(image);
        this.bucket.setPositionRound(styles);
        this.addChild(this.bucket);

        var flyFlag;
        cleverapps.UI.onDrag(this.bucket, {
            onDragStart: function (touch) {
                if (!this.game.running || this.game.paused) {
                    return false;
                }
                flyFlag = this.getFlyFlag();
                flyFlag.runAction(new cc.MoveTo(0.01, flyFlag.parent.convertTouchToNodeSpace(touch)));
                return true;
            }.bind(this),

            onDragMove: function (touch) {
                if (flyFlag && flyFlag.parent) {
                    flyFlag.stopAllActions();
                    flyFlag.runAction(new cc.MoveTo(0.01, flyFlag.parent.convertTouchToNodeSpace(touch)));
                }
            },

            onDragEnd: function (touch) {
                if (flyFlag && flyFlag.parent) {
                    var index = this.calculateIndex(touch.getLocation());
                    if (index && this.game.hasElement(index.col, index.row) && this.game.running && !this.game.paused && (!this.game.flags || !this.game.flags[index.row][index.col])) {
                        this.game.switchFlag(index.col, index.row);
                    } else {
                        this.removeFlyFlag();
                    }
                }
            }.bind(this)
        });

        cleverapps.UI.applyHover(this.bucket, {
            filter: function () {
                return this.game.running && !this.game.paused;
            }.bind(this)
        });
    },

    hideFieldNode: function () {
        this.fieldNode.performRecursive(function (node) {
            node.setCascadeOpacityEnabled(true);
        });
        this.fieldNode.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.RemoveSelf()
        ));
    },

    hideTopBlock: function () {
        this.topBlock.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.RemoveSelf()
        ));
    },

    removeFlag: function (options) {
        var animation = this.field[options.row][options.col];
        if (animation.flag) {
            animation.flag.removeFromParent();
            animation.flag = undefined;
            if (!options.silent) {
                this.removeFlyFlag();
                var flag = this.getFlyFlag({
                    pos: cleverapps.styles.MineSweeperNode.flag.cell,
                    parent: animation
                });
                flag.replaceParentSamePlace(this.bucket);
                this.removeFlyFlag();
            }
        }
    },

    getFlyFlag: function (options) {
        if (!this.flyFlag) {
            var styles = cleverapps.styles.MineSweeperNode.flag;
            options = options || {};
            var pos = options.pos || styles.bucket;
            var parent = options.parent || this.bucket;
            var scale = options.parent ? 1 : styles.bucket.scale;
            this.flyFlag = new cc.Sprite(bundles.minesweeper.frames.flag_png);
            this.flyFlag.setScale(scale);
            parent.addChild(this.flyFlag);
            this.flyFlag.setPositionRound(pos);
        }
        return this.flyFlag;
    },

    getFlyFlagTime: function (pos) {
        var flyFlag = this.getFlyFlag();
        var time = cc.pDistance(flyFlag.getPosition(), pos) / cleverapps.styles.MineSweeperNode.flag.speed;
        return Math.max(time, 0.15);
    },

    removeFlyFlag: function () {
        if (this.flyFlag) {
            var pos = cleverapps.styles.MineSweeperNode.flag.bucket;
            var time = this.getFlyFlagTime(pos);
            this.flyFlag.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.MoveTo(time, pos),
                    new cc.ScaleTo(time, cleverapps.styles.MineSweeperNode.flag.bucket.scale)
                ),
                new cc.RemoveSelf()
            ));
            this.flyFlag = undefined;
        }
    },

    addFlag: function (options) {
        var styles = cleverapps.styles.MineSweeperNode.flag;
        var animation = this.field[options.row][options.col];
        var flyFlag = this.getFlyFlag();
        var pos = flyFlag.parent.convertToNodeSpace(animation.convertToWorldSpace(styles.cell));
        var time = this.getFlyFlagTime(pos);
        flyFlag.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveTo(time, pos),
                new cc.ScaleTo(time, 1)
            ),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                if (this.game.flags[options.row][options.col]) {
                    animation.flag = new cc.Sprite(bundles.minesweeper.frames.flag_png);
                    animation.addChild(animation.flag);
                    animation.flag.setPositionRound(styles.stableCell);
                }
            }.bind(this))
        ));
        this.flyFlag = undefined;
    },

    controls: function () {
        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseDown: this.onMouseDown.bind(this),
            onMouseMove: this.onMouseMove.bind(this)
        }, this.fieldNode);

        cleverapps.UI.onClick(this.fieldNode, this.onClicked.bind(this), {
            interactiveScale: false,
            filter: function () {
                return this.game.running && !this.game.paused && !this.hammerAnimation;
            }.bind(this)
        });
    },

    calculateIndex: function (pos) {
        var location = this.fieldNode.convertToNodeSpace(pos);
        var rect = cc.rect(0, 0, this.fieldNode.width, this.fieldNode.height);
        if (cc.rectContainsPoint(rect, location)) {
            var styles = cleverapps.styles.MineSweeperNode.field.cell;
            var col = Math.floor(location.x / styles.width);
            var row = Math.floor(location.y / styles.height);
            return {
                row: Math.max(Math.min(row, MineSweeper.HEIGHT - 1), 0),
                col: Math.max(Math.min(col, MineSweeper.WIDTH - 1), 0)
            };
        }
    },

    onClicked: function (touch) {
        var startIndex = this.calculateIndex(touch.getStartLocation());
        var index = this.calculateIndex(touch.getLocation());

        if (index && startIndex && index.row === startIndex.row && index.col === startIndex.col && this.game.hasElement(index.col, index.row)) {
            if (this.rightMouseButton || (this.game.flags && this.game.flags[index.row][index.col])) {
                this.game.switchFlag(index.col, index.row);
                return;
            }

            var styles = cleverapps.styles.MineSweeperNode.hammer;
            this.hammerAnimation = true;
            var hammer = new cleverapps.Spine(bundles.minesweeper.jsons.custom_cursor_json);
            hammer.setScale(styles.scale || 1.0);
            var animation = this.field[index.row][index.col];
            hammer.setPositionRound(styles);
            hammer.setLocalZOrder(10);
            animation.getParent().addChild(hammer);
            hammer.replaceParentSamePlace(this.getParent());

            hammer.setAnimation(0, "animation", false);
            hammer.setCompleteListener(function () {
                hammer.runAction(new cc.Sequence(
                    new cc.ScaleTo(0.15, 0),
                    new cc.RemoveSelf()
                ));
            });

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.35),
                new cc.CallFunc(function () {
                    this.hammerAnimation = false;
                    this.game.open(index.col, index.row);
                    this.updateRewardsAmount(this.rewardTimeouts, 0.65);
                    this.rewardTimeouts = undefined;
                }.bind(this))
            ));
        }
    },

    onMouseDown: function (event) {
        if (!cleverapps.focusManager.checkEventNode(this.fieldNode)) {
            return false;
        }

        this.rightMouseButton = false;
        if (event.getButton()) {
            this.rightMouseButton = true;
        }
    },

    onMouseMove: function (touch) {
        if (!cleverapps.focusManager.checkEventNode(this.fieldNode)) {
            return false;
        }

        if (!this.game.running || this.game.paused) {
            return;
        }

        var index = this.calculateIndex(touch.getLocation());
        var oldIndex = this.index;
        if (index && this.game.hasElement(index.col, index.row)) {
            if (this.index === undefined || ((index.row * 100 + index.col) !== (this.index.row * 100 + this.index.col))) {
                this.index = index;
                this.field[index.row][index.col].stopAllActions();
                this.field[index.row][index.col].setScale(1.05);
            }
        }
        if (oldIndex && this.index !== oldIndex) {
            this.field[oldIndex.row][oldIndex.col].stopAllActions();
            this.field[oldIndex.row][oldIndex.col].runAction(new cc.ScaleTo(0.15, 1));
        }
    },

    createScore: function () {
        var styles = cleverapps.styles.MineSweeperNode;
        this.scores = {};

        var scores = [MineSweeper.ELEMENT_RUBY, MineSweeper.ELEMENT_ENERGY, MineSweeper.ELEMENT_COIN].map(function (type) {
            var icon = new cc.Sprite(this.getRewardIcon(type));
            icon.setScale(styles.topBlock.icon.scale);

            var rewardText = cleverapps.UI.generateImageText("0", cleverapps.styles.FONTS.MINESWEEPER_TEXT_TOP);
            rewardText.setLocalZOrder(1);
            rewardText.baseScale = rewardText.scale;

            var score = new cleverapps.Layout([rewardText, icon], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.topBlock.scoreMargin
            });
            score.rewardText = rewardText;
            this.scores[type] = score;
            score.setCascadeOpacityEnabled(true);
            return score;
        }, this);

        var scoresNode = new cleverapps.Layout(scores, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.topBlock.betweenScores
        });
        scoresNode.setCascadeOpacityEnabled(true);

        var text = cleverapps.UI.generateOnlyText("minesweeper.prize", cleverapps.styles.FONTS.MINESWEEPER_TEXT_TOP);

        var topBlock = this.topBlock = new cleverapps.Layout([text, scoresNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.topBlock.margin
        });
        this.addChild(topBlock);
        topBlock.setCascadeOpacityEnabled(true);
        topBlock.setPositionRound(styles.topBlock);
    },

    createField: function () {
        var styles = cleverapps.styles.MineSweeperNode.field;
        this.field = [];

        for (var row = 0; row < this.game.height; row++) {
            var itemsRow = [];

            for (var col = 0; col < this.game.width; col++) {
                var item = new cleverapps.Spine(bundles.minesweeper.jsons.minesweeper);
                item.setSkin((row + col) % 2 === 0 ? "1" : "2");
                item.setAnimation(0, "idle", true);
                itemsRow.push(item);
            }

            this.field.push(itemsRow);
        }

        var lines = this.field.map(function (row) {
            return new cleverapps.Layout(row.map(function (animation) {
                var wrap = new cc.Node();
                wrap.setAnchorPoint(0.5, 0.5);
                wrap.setContentSize2(styles.cell.width, styles.cell.height);
                animation.setPositionRound(wrap.width / 2, wrap.height / 2);
                wrap.addChild(animation);
                return wrap;
            }), {
                direction: cleverapps.UI.HORIZONTAL
            });
        });

        this.fieldNode = new cleverapps.Layout(lines, {
            direction: cleverapps.UI.VERTICAL,
            reversed: true
        });
        this.addChild(this.fieldNode);
        this.fieldNode.setPositionRound(styles);
    },

    openField: function () {
        for (var i = 0; i < this.field.length; i++) {
            for (var j = 0; j < this.field[i].length; j++) {
                var item = this.field[i][j];
                if (this.game.hasElement(j, i)) {
                    item.setAnimation(1, "boom", false);
                }
            }
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.MineSweeperNode.progress;

        this.cellsToOpenProgress = new ScaledProgressBar({
            progress: bundles.minesweeper.frames.bar_minesweeper
        });
        this.cellsToOpenProgress.setAnchorPoint(0, 0.5);
        this.cellsToOpenProgress.setLength(styles.width);
        this.cellsToOpenProgress.setPositionRound(styles);
        this.addChild(this.cellsToOpenProgress);
        this.cellsToOpenProgress.setCascadeOpacityEnabled(true);

        this.cellsToOpen = cleverapps.UI.generateImageText("0/" + this.game.cellsToOpen, cleverapps.styles.FONTS.MINESWEEPER_PROGRESS);
        this.cellsToOpen.baseScale = this.cellsToOpen.scale;
        this.cellsToOpen.total = this.game.cellsToOpen;
        this.cellsToOpen.setPositionRound(styles.text);
        this.cellsToOpenProgress.addChild(this.cellsToOpen);
    },

    updateProgress: function (amount) {
        this.cellsToOpenProgress.setGoal(this.cellsToOpen.total);
        this.cellsToOpenProgress.setPercentage(this.cellsToOpen.total - amount, {
            animated: true
        });

        this.cellsToOpen.setString((this.cellsToOpen.total - amount) + "/" + this.cellsToOpen.total);
        this.cellsToOpen.stopAllActions();
        this.cellsToOpen.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, 1.3 * this.cellsToOpen.baseScale),
            new cc.ScaleTo(0.15, 1 * this.cellsToOpen.baseScale)
        ));
    },

    hideProgress: function () {
        this.cellsToOpenProgress.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.RemoveSelf()
        ));
    },

    bomb: function (options) {
        var animation = this.field[options.row][options.col];
        animation.setLocalZOrder(1);
        animation.replaceParentSamePlace(this);
        animation.bomb.removeFromParent();
        animation.setAnimation(2, "bomb", false);
    },

    showBomb: function (options) {
        var animation = this.field[options.row][options.col];
        var bomb = animation.bomb = new cleverapps.Spine(bundles.minesweeper.jsons.minesweeper);
        bomb.setLocalZOrder(animation.getLocalZOrder() - 1);
        animation.getParent().addChild(bomb);
        bomb.setPositionRound(animation.getPosition());
        bomb.setAnimation(0, "bomb_idle", false);
    },

    getRewardIcon: function (type) {
        switch (type) {
            case MineSweeper.ELEMENT_RUBY:
                return bundles.menubar.frames.gold_png;
            case MineSweeper.ELEMENT_ENERGY:
                return cleverapps.skins.getSlot("menubar_life_png") || bundles.menubar.frames.menubar_life_png;
            case MineSweeper.ELEMENT_COIN:
                return bundles.menubar.frames.coin_png;
        }
    },

    createReward: function (options) {
        var animation = this.field[options.row][options.col];

        var icon = new cc.Sprite(this.getRewardIcon(options.prize.type));
        icon.setScale(0.9);

        var reward = new cc.Node();
        reward.setAnchorPoint(0.5, 0.5);
        reward.setContentSize2(cleverapps.styles.MineSweeperNode.field.cell);

        reward.addChild(icon);
        icon.setPositionRound(reward.width / 2, reward.height / 2);

        reward.setPositionRound(animation.getPosition());
        reward.setCascadeOpacityEnabled(true);
        reward.setLocalZOrder(1);
        animation.getParent().addChild(reward);

        return reward;
    },

    showPrize: function (options) {
        var reward = this.createReward(options);
        reward.runAction(new cc.Spawn(
            new cc.ScaleTo(1, 1).easing(cc.easeBackOut()),
            new cc.FadeIn(1)
        ));
    },

    explodePrize: function (options) {
        this.updateRewardAmount(options);
        this.updateRewardsAmount(this.rewardTimeouts, 0.15);
        var styles = cleverapps.styles.MineSweeperNode.explodePrize;

        var flyOneIcon = function (icon) {
            var dist = {
                x: Math.random() * 2 * styles.dx - styles.dx,
                y: styles.dy
            };

            var time = 0.6 + Math.random() * 0.3;
            icon.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.RotateBy(time, 150 + Math.random() * 150),
                    new cc.ScaleTo(time, 1.4),
                    new cc.JumpAnimation(time, icon, dist, styles.h + Math.random() * styles.dh)
                ),
                new cc.RemoveSelf()
            ));
        };

        var amount = -options.amount;
        if (amount > 8) {
            amount = 8;
        }
        for (var i = 0; i < amount; i++) {
            var icon = new cc.Sprite(this.getRewardIcon(options.type));
            icon.setScale(0.9);
            var target = this.scores[options.type];
            target.getParent().addChild(icon);
            icon.setPosition(target.getPosition());
            flyOneIcon(icon);
        }
    },

    prize: function (options) {
        var reward = this.createReward(options);
        this.updateRewardAmount(options.prize);
        reward.runAction(new cc.Sequence(
            new cc.ScaleTo(0, reward.scale * 0.5),
            StandartAnimations.jumpCollect(reward, this.scores[options.prize.type], {
                jumpScale: reward.scale * 1.2,
                collectScale: reward.scale,
                collectSound: bundles.minesweeper.urls.mine_vin_sfx
            }),
            new cc.RemoveSelf()
        ));
    },

    open: function (options) {
        var animation = this.field[options.row][options.col];
        animation.setAnimation(1, "boom", false);
        cleverapps.audio.playSound(bundles.minesweeper.urls.pickaxe_sfx);

        if (options.bombs) {
            var bombs = cleverapps.UI.generateImageText(options.bombs, cleverapps.styles.FONTS.MINESWEEPER_BOMBS);
            animation.getParent().addChild(bombs);
            bombs.setColor(cleverapps.styles.MineSweeperNode.bombs["color" + options.bombs]);
            bombs.setPositionRound(cleverapps.styles.MineSweeperNode.bombs);
        }

        if (options.bomb) {
            this.showBomb(options);
            cleverapps.audio.playSound(bundles.minesweeper.urls.miner_fail_sfx);
        } else {
            cleverapps.audio.playSound(bundles.minesweeper.urls.miner_good_sfx);
        }
    },

    updateRewardAmount: function (options) {
        if (!this.rewardTimeouts) {
            this.rewardTimeouts = {};
        }
        if (!this.rewardTimeouts[options.type]) {
            this.rewardTimeouts[options.type] = 0;
        }

        this.rewardTimeouts[options.type] += options.amount;
    },

    updateRewardsAmount: function (rewardTimeouts, delay) {
        rewardTimeouts = rewardTimeouts || {};
        delay = delay || 0;

        for (var type in rewardTimeouts) {
            if (!rewardTimeouts[type]) {
                return;
            }

            var rewardText = this.scores[type].rewardText;
            var deltaPosition = this.convertToNodeSpace(rewardText.parent.convertToWorldSpace(rewardText.getPosition()));
            deltaPosition.y += cleverapps.styles.MineSweeperNode.prize.delta;

            this.scores[type].runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.Sequence(
                    new cc.CallFunc(function (type) {
                        this.scores[type].rewardText.setString(this.game.reward[type]);
                        this.scores[type].rewardText.runAction(new cc.Sequence(
                            new cc.ScaleTo(0.1, 1.4 * this.scores[type].rewardText.baseScale),
                            new cc.ScaleTo(0.25, 1 * this.scores[type].rewardText.baseScale)
                        ));

                        cleverapps.aims.showDelta(rewardTimeouts[type], this, {
                            x: deltaPosition.x,
                            y: deltaPosition.y
                        });
                    }.bind(this, type))

                )
            ));
        }
    },

    setupChildren: function () {
        this.bucket.setPositionRound(cleverapps.styles.MineSweeperNode.bucket);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MINESWEEPER_BOMBS: {
        name: "default",
        size: 70,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MINESWEEPER_TEXT_TOP: {
        name: "default",
        size: 55,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MINESWEEPER_PROGRESS: {
        name: "default",
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MINESWEEPER_TEXT_EXIT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.MineSweeperNode = {
    width: 1020,
    height: 1260,

    bg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    topBlock: {
        margin: 0,
        betweenScores: 60,
        scoreMargin: 17,
        x: { align: "center" },
        y: { align: "top", dy: -13 },
        icon: {
            scale: 0.7
        }
    },

    field: {
        x: { align: "center" },
        y: { align: "center", dy: -57 },

        cell: {
            width: 123,
            height: 123
        }
    },

    progress: {
        x: { align: "center", dx: 11 },
        y: { align: "bottom", dy: 25 },
        width: 960,
        text: {
            x: { align: "center" },
            y: { align: "center", dy: 1 }
        }
    },

    loseText: {
        x: { align: "center" },
        y: { align: "top", dy: 200 }
    },

    prize: {
        dx: 100,
        dy: 50,
        margin: 20,
        padding: 0,
        delta: 30
    },

    explodePrize: {
        dx: 1000,
        dy: -1500,
        h: 1200,
        dh: 400
    },

    reward: {
        amount: {
            x: { align: "center", dx: 20 },
            y: { align: "bottom", dy: 0 }
        }
    },

    hammer: {
        x: { align: "center" },
        y: { align: "center" },
        scale: 1.2
    },

    bucket: {
        x: { align: "right", dx: 256 },
        y: { align: "bottom", dy: -71 },
        width: 250,
        height: 300,
        image: {
            x: { align: "center", dx: 30 },
            y: { align: "center", dy: 20 }
        }
    },

    flag: {
        bucket: {
            x: 150,
            y: 230,
            scale: 2
        },
        cell: {
            x: 155,
            y: 53
        },
        stableCell: {
            x: { align: "center" },
            y: { align: "center" }
        },
        speed: 4000
    },

    bombs: {
        x: { align: "center" },
        y: { align: "center", dy: -3 },
        "color1": new cc.Color(62, 113, 235, 255),
        "color2": new cc.Color(56, 215, 66, 255),
        "color3": new cc.Color(219, 81, 46, 255),
        "color4": new cc.Color(222, 207, 42, 255),
        "color5": new cc.Color(189, 67, 204, 255),
        "color6": new cc.Color(79, 178, 183, 255),
        "color7": new cc.Color(218, 57, 84, 255),
        "color8": new cc.Color(100, 49, 221, 255)
    }
};
