/**
 * Created by dmitry on 18.03.2025
 */

var LastPurchase = function (offer) {
    this.offer = offer;
};

LastPurchase.prototype.processBought = function (success, product) {
    if (!success) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER + this.offer.name);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER_BUY + this.offer.name + "_" + product.itemId);
    this.offer.complete();
};

LastPurchase.prototype.getProduct = function () {
    return cleverapps.paymentsHistory.getFavoriteProduct({ offer: Offers.TYPES.LAST_PURCHASE });
};

LastPurchase.prototype.getTimeLeft = function () {
    return undefined;
};

LastPurchase.prototype.isIconAvailable = function () {
    return false;
};

ABExperiments.HAS_LAST_PURCHASE = function () {
    return ABExperiments.Tests && cleverapps.abExperiments.getGroup(ABExperiments.Tests.lastBuy) === "A";
};

LastPurchase.BONUS_REWARD = {
    energy: 90
};
