/**
 * Created by iamso on 28.05.21.
 */

var MineVictoryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.reward = options.reward;
        this.lose = options.lose;

        this._super({
            name: "minevictorywindow",
            title: this.lose ? "RewardWindow.title" : "MineVictoryWindow.title",
            content: this.createContent(),
            noBackground: true,
            closeButtonDelay: true
        });

        this.windowTitle.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.ScaleTo(0.1, 1.1),
            new cc.ScaleTo(0.05, 1)
        ));
    },

    getPerson: function () {
        return !this.lose && {
            role: "miner",
            emotion: "happy"
        };
    },

    createContent: function () {
        var styles = cleverapps.styles.MineVictoryWindow;

        var animation = new cleverapps.Spine(bundles.minesweeper.jsons.victory_animation);
        animation.setScale(styles.animation.scale || 1.0);
        animation.setPositionRound(styles.animation);
        animation.setAnimationAndIdleAfter("emergence", "idle");

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles.animation);
        container.addChild(animation);

        var rewards = new RewardsListComponent(Object.assign({
            hard: 0,
            soft: 0,
            energy: 0
        }, this.reward), {
            font: cleverapps.styles.FONTS.MINEVICTORY_REWARDS_TEXT,
            iconScale: styles.rewards.scale,
            margin: styles.rewards.margin
        });
        rewards.setPositionRound(styles.rewards);
        rewards.setScale(0);
        container.addChild(rewards);

        rewards.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.ScaleTo(0.2, 1)
        ));

        cleverapps.UI.onClick(container, this.close.bind(this), {
            interactiveScale: false
        });

        return container;
    },

    onClose: function () {
        cleverapps.focusManager.distract({
            focus: "backToMain",
            action: function (f) {
                cleverapps.meta.gotoMainLocation(f);
            }
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MINEVICTORY_REWARDS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.MineVictoryWindow = {
    animation: {
        width: 1000,
        height: 800,

        x: { align: "center" },
        y: { align: "center", dy: -90 },
        scale: 1.3
    },

    rewards: {
        x: { align: "center", dx: -25 },
        y: { align: "center", dy: 125 },
        margin: {
            x: 30
        },
        scale: 1.4
    }
};
