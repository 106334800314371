/**
 * Created by mac on 12/8/20
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    TITLE_COLOR_YELLOW: new cc.Color(134, 56, 10, 255),
    TITLE_SHADOW: new cc.Color(255, 251, 126, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    WHITE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PACKWINDOW_LABEL_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_TIMER_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_PROGRESS_IMAGE_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_TICKET_TEXT: {
        size: 35,
        color: new cc.Color(139, 81, 42)
    },

    PASS_TICKET_SMALL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    PASS_REWARD_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_PROGRESS_POINT: {
        size: 40,
        color: new cc.Color(144, 189, 210)
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: -0.7,
            dx: 0,
            dy: -20
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 1.5,
        duration: 0.675,
        fitTo: {
            width: 166
        },

        positions: {
            "default": {
                "hard": {
                    x: 125,
                    y: -164
                },
                "energy": {
                    x: -145,
                    y: -164
                },
                "wands": {
                    x: -275,
                    y: -101
                },
                "soft": {
                    x: -9,
                    y: -98
                },
                "worker": {
                    x: -204,
                    y: 156
                }
            },

            "starterPack": {
                "hard": {
                    x: 196,
                    y: 154
                },
                "energy": {
                    x: 281,
                    y: -104
                }
            },

            "starterPack2": {
                "hard": {
                    x: 195,
                    y: 157
                },
                "energy": {
                    x: 239,
                    y: -207
                },
                "wands": {
                    x: -229,
                    y: -207
                },
                "worker": {
                    x: -205,
                    y: 158
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.InfoView, {
    button: {
        type: {
            default: cleverapps.styles.UI.Button.Images.small_button_green,
            secondary: cleverapps.styles.UI.Button.Images.small_button_blue
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GiftContentView, {
    width: 640,
    minHeight: 160
});

cleverapps.overrideStyles(cleverapps.styles.UI.CheckBox, {
    label: {
        offsetY: -8
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTicket, {
    margin: 0,

    free: {
        text: 235,
        x: { align: "center", dx: 15 },
        y: { align: "center", dy: 19 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    premium: {
        x: { align: "center", dx: 15 },
        y: { align: "bottom", dy: 19 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeader, {
    decor: {
        x: { align: "center", dx: -600 }
    },

    pack: {
        x: { align: "right", dx: 15 },
        y: { align: "center", dy: 5 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassHeader, {
    title: {
        x: { align: "center" },
        y: { align: "top", dy: -60 }
    },
    amount: {
        x: { align: "center" },
        y: { align: "center", dy: -80 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitsShopTile, {
    title: {
        width: 480
    }
});

cleverapps.overrideStyles(cleverapps.styles.FixedWidthScene, {
    clearColor: new cc.Color(52, 150, 205, 255)
});

cleverapps.overrideStyles(cleverapps.styles.ThirdElementWindow, {
    infoBlock: {
        timer: {
            x: { align: "left", dx: 50 },
            y: { align: "center", dy: 10 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetY: 280,
        offsetX: 260,

        title: {
            x: { align: "center", dx: 220 },
            y: { align: "center", dy: -95 },
            width: 280
        }
    },
    tap: {
        x: { align: "center", dx: -320 },
        y: { align: "center", dy: 530 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    person: "worker"
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionWindow, {
    width: 1200,
    margin: 45
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.EXPEDITION], {
    badges: [
        {
            x: { align: "center", dx: 737 },
            y: { align: "center", dy: -268 },
            largeFont: true
        }
    ],

    rewards: {
        unit: {
            dragonpack: {
                x: { align: "center", dx: -533 },
                y: { align: "center", dy: -397 }
            }
        },
        hard: {
            x: { align: "center", dx: 577 },
            y: { align: "center", dy: -377 },
            rotation: -1
        }
    }
});
cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.DRAGONIA2], {
    badges: [
        {
            x: { align: "center", dx: 737 },
            y: { align: "center", dy: -268 },
            largeFont: true
        }
    ],

    rewards: {
        unit: {
            dr2dragonpack: {
                x: { align: "center", dx: -533 },
                y: { align: "center", dy: -397 }
            }
        },
        hard: {
            x: { align: "center", dx: 577 },
            y: { align: "center", dy: -377 },
            rotation: -1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorX: 0.15,
            factorY: 0.86
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    padding: [
        {
            top: 136
        },
        {
            top: 186
        },
        {
            top: 191
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    padding: [{
        x: -5
    }, {
        x: -5
    }, {
        x: -5
    }]
});